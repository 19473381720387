import { render, staticRenderFns } from "./404.vue?vue&type=template&id=d010b69c&scoped=true&lang=pug&"
var script = {}
import style0 from "./404.vue?vue&type=style&index=0&id=d010b69c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d010b69c",
  null
  
)

export default component.exports