// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/subset-MADETOMMY-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/subset-MADETOMMY.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/subset-MADETOMMY-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/subset-MADETOMMY-Black.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("assets/fonts/subset-MADETOMMYOutline-Black.woff2");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:200;font-family:\"MADETOMMY\"}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-family:\"MADETOMMY\"}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:600;font-family:\"MADETOMMY\"}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:800;font-family:\"MADETOMMY\"}@font-face{src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:800;font-family:\"MADETOMMY OUTLINE\"}html{--text: #EDECED;--background: #231f20;--border: #ff2e2e}*,*::before,*::after{margin:0;padding:0;box-sizing:border-box;scrollbar-width:thin;scrollbar-color:var(--text) #000;-webkit-tap-highlight-color:transparent}::-webkit-scrollbar{width:4px;height:4px;background-color:transparent}::-webkit-scrollbar-thumb{background:var(--text)}html{overflow:hidden;height:100%}body{font-family:\"MADETOMMY\",sans-serif;background-color:var(--background);color:var(--text);height:100%}button{background-color:transparent;border:none;fill:#fff;cursor:pointer}button:hover{color:var(--border);fill:var(--border)}a,a svg{color:var(--text);fill:var(--text)}a:hover,a:hover svg{color:var(--border);fill:var(--border)}a:focus,button:focus{outline:1px dotted var(--border)}", ""]);
// Exports
module.exports = exports;
